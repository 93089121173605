<template>
  <div>
    <div
      class=" font-weight-light"
      :class="{'pa-5 headline': $vuetify.breakpoint.lgAndUp,'pa-2 subtitle-1': $vuetify.breakpoint.mdOnly, 'pa-1 body-2': $vuetify.breakpoint.smAndDown}"
    >
      <div class="font-weight-medium"
           :class="{'display-1': $vuetify.breakpoint.lgAndUp,'headline': $vuetify.breakpoint.mdOnly, 'subheading': $vuetify.breakpoint.smAndDown}"
      >
        <span style="font-family:Oswald"> Coordinates of a Point</span>
      </div>
      <div ref="ia" style="margin-top: 20px" />
      The position of any object lying in a plane
      can be represented by its distance from two perpendicular axes (lines). These axes are called coordinate axes, and the distances from these two axes used in specifying the location of
      the object are called its coordinates.<br><br>
      The coordinates of a point in a plane is represented by a pair of numbers ( x , y ). The x value represents the horizontal location, while y represents the vertical location.<br><br>
      These coordinates are measured in relation to a central point called the origin. The coordinates of the origin are (0, 0).
      <br><br> Positive x-coordinates are to the right of the origin, and negative x-coordinates are to the left. Positive y-coordinates are above the origin, and negative y-coordinates are below.<br><br>
      Let's take an example.Consider a point with coordinates (5,2). This point would be located 5 units to the right and 2 units above the origin. Similarly, a point with coordinates (3, -4) would be located 3 units to the right and 4 units below the origin.
      <div style="margin-top: 20px" />
      <div class="font-weight-medium"
           :class="{'display-1': $vuetify.breakpoint.lgAndUp,'headline': $vuetify.breakpoint.mdOnly, 'subheading': $vuetify.breakpoint.smAndDown}"
      >
        <span style="font-family:Oswald"> Learn Interactively | Coordinates of a Point</span>
      </div>
      <div style="margin-top: 20px" />
      <h5> Session Objectives </h5>
      <div style="margin-top: 10px" />
      Shown on the graph below is a point named P. You will learn to find the coordinates of point P using a step by step approach.
      <div style="margin-top: 10px" />
      <h5> Getting Started </h5>
      <div style="margin-top: 10px" />
      Point P is draggable. Start by placing the point P at a location of your choice.
      Then, use the navigation icons at the bottom of the graph to learn how to determine the coordinates of this point in a step by step fashion. Tap on the <i class="next ma-1" /> icon to go to one step forward.Tap on the <i class="next ma-1" /> icon to go to one step backward.<br><br>
      Once done, you can move the point around and see how its coordinates change in real time. To practice with a new point position, simply tap on the <i class="shuffler ma-1" /> icon to shuffle the point position. Tap on the <i class="undoer ma-1" /> icon to start over.
    </div>
    <div ref="ib" style="margin-top: 10px" />
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
      <v-layout justify-center>
        <h5 class="edliy-text-about">
          How to Play: Drag point <i>P</i> at a location of your choice. Then use the navigation icons <i class="next ma-1" /> and <i class="previous ma-1" /> to follow the steps to determine P's coordinates.
        </h5>
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'CoordinatesPoint',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Coordinates of a Point';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Coordinates of Point',img:'/assets/number-1.svg', action:()=>this.goto('ia')},
      {title: 'Interactive Session', img:'/assets/number-2.svg', action:() => this.goto('ib')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    this.$store.commit('navigation/toggleshowhome', false);
    this.$store.commit('navigation/replaceMath', true);
    this.$store.commit('navigation/replaceLeftArrow', true);
    this.$store.commit('navigation/replaceModule', true);
    this.$store.commit('navigation/replaceSample', true);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Coordinate Geometry',
          titleTemplate: '%s | Lesson 1',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Lesson on coordinate goemetry'}
                ]
        }
   },
}
</script>
