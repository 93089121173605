// Import the edliy_utils
import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeMiddleText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeRightText,
    placeSliderSwitch,
    placeCircle,
    placeLogo,
    placeShuffle,
    placeTest,
    placeUndo,
    placeRedo,
    placeBoldText,
    placeBoldLeftText,
    placeEraseLeft,
    placeStat,
    placeLabel,
    placeTapeHori,
    placeTapeVert,
    toggle,
    placeText,
    placeGo,
    placeStartOver
} from '../../Utils';
const Boxes = {
  box1: function () {
  JXG.Options.board.minimizeReflow = 'none';
  var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-10, 10, 10, -10], keepaspectratio: true, axis:false, ticks:{visible:false},
  grid:true, showCopyright:false, showNavigation:false,
  pan:{enabled:false}, zoom:{enabled:false}});
///////////////////////////////OPTIONS/////////////////////////////
JXG.Options.board.minimizeReflow = 'none';
JXG.Options.point.highlight=false;
JXG.Options.image.highlight=false;
JXG.Options.text.highlight=false;
JXG.Options.text.fixed=true;
JXG.Options.curve.highlight=false;
JXG.Options.text.cssDefaultStyle='fontFamily:Oswald;'
////////////////////////////////LAYERING////////////////////////////////
brd1.options.layer['image'] =12;
brd1.options.layer['circle'] =13;
brd1.options.layer['chart'] =14;
brd1.options.layer['sector'] =15;
brd1.options.layer['line'] =15;
brd1.options.layer['point'] =20;
brd1.options.point.showInfoBox=false;
//////////////////////////////BASICS/////////////////////
makeResponsive(brd1);
placeTitle(brd1, 'Coordinates of a Point', '');
placeLogo(brd1);
///////////////////////////////////BUTTONS//////////////////////
//var analytics =placeGo(brd1, 'left');
//var erase =placeTest(brd1);
var xp =3;
var yp =4;
/////////////////////////////////CONTROLLERS////////////////////////////
var i=0;
////////////////////////////////////////////////////////////////////////
var pts = brd1.create('point', [3, 4], {name:'', highlight:false, size:function(){return 4*brd1.canvasHeight/800}, fillColor:'orange', strokeColor:'black', strokeWidth:2, visible:true, snapToGrid:true, shadow:false});
//
var prev =placeUndo(brd1,'left');
prev.setAttribute({visible:()=>i>0});
var sover=placeStartOver(brd1, 'right');
sover.setAttribute({visible:()=>i==3});
sover.on('down',function(){pts.moveTo([3,4]);i=0});
var next =placeRedo(brd1);
next.setAttribute({visible:()=>i<3});
prev.on('down',function(){if(i>0){i-=1}else{i=0}});
next.on('down',function(){if(i<3){i+=1}else{i=3}});
//
brd1.create('text', [()=>pts.X()+Math.sign(pts.X())*0.35, ()=>pts.Y()+Math.sign(pts.Y())*0.35, 'P (Drag me)'], {display:'internal', anchorX:()=>pts.X()>=0?'left':'right', anchorY:()=>'middle',fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;fontWeight:bold'});
//
placeLeftText(brd1, -9, 7.5, 'Distance along x-axis').setAttribute({color:'blue', visible:()=>i>=1});
//
brd1.create('text', [-9, 6.5, function(){return 'x = ' +(pts.X()).toFixed(2) + ' units';}], {visible:()=>i>=1, anchorx:'left',display:'internal',fixed:true, fontSize:function(){return 20*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
//
placeLeftText(brd1, -9, 5.5, 'Distance along y-axis').setAttribute({color:'blue', visible:()=>i>=2});
//
placeLeftText(brd1, -9, 3.5, 'Coordinates of Point P').setAttribute({color:'blue', visible:()=>i>=3});
//
brd1.create('text', [-9, 2.5, ()=>'P = ( x, y ) = ( '+(pts.X()).toFixed(0)+' , '+(pts.Y()).toFixed(0)+' )'], {visible:()=>i>=3, anchorx:'left',display:'internal',fixed:true, fontSize:function(){return 20*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
//
brd1.create('text', [-9,4.5, function(){return 'y = ' +(pts.Y()).toFixed(2) + ' units';}], {visible:()=>i>=2, anchorx:'left',display:'internal',fixed:true, fontSize:function(){return 20*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
//////////////////////////////////////////////////////
var xcord=brd1.create('segment', [[0, ()=>pts.Y()], [()=>pts.X(), ()=>pts.Y()]],{visible:()=>i>=1, dash:1, strokeWidth:2, strokeColor:'black'});
//
brd1.create('text', [()=>pts.X()/2., ()=>pts.Y()+Math.sign(pts.Y())*0.5, ()=>''+(pts.X()).toFixed(0)+' units'], {visible:()=>i>=1, display:'internal', anchorX:'middle', anchorY:()=>'middle',fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;fontWeight:bold'});
//
var ycord=brd1.create('segment', [[()=>pts.X(), 0],[()=>pts.X(), ()=>pts.Y()]],{visible:()=>i>=2, dash:1, strokeWidth:2, strokeColor:'black'});
//
brd1.create('text', [()=>pts.X()+Math.sign(pts.X())*0.25, ()=>pts.Y()/2, ()=>''+(pts.Y()).toFixed(0)+' units'], {visible:()=>i>=2, display:'internal', anchorX:()=>pts.X()>=0?'left':'right', anchorY:'middle', fixed:true, fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;fontWeight:bold'});
//
brd1.create('axis', [[0,0],[0,4]],{highlight:false, strokeColor:'grey', strokeWidth:2, name:'y', withLabel:true, label:{position:'urt',fontSize:function(){return 24*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
//
brd1.create('axis', [[0,0],[4,0]],{highlight:false, strokeColor:'grey', strokeWidth:2, name:'x', withLabel:true, label:{position:'urt', fontSize:function(){return 24*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
    },
}
export default Boxes;
